module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/me/src/brothers-public.com/src/components/layout.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/me/src/brothers-public.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-6T9G6EQD18","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Boucherie Block","Boucherie Flared"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brothers Public Kitchen | Orinda, CA","short_name":"Brothers Public Kitchen","start_url":"/","background_color":"#fff","theme_color":"#231f20","icon":"src/svg/stamp-bw.svg","icons":[{"src":"/icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"display":"standalone","shortcuts":[{"name":"View Dining Menu","short_name":"Menu","description":"See our full menu - Dinner, Happy Hour, Cocktails, and more.","url":"/brothers-menu.pdf","icons":[{"src":"/image/shortcut/book-x48.png","sizes":"48x48"},{"src":"/image/shortcut/book-x72.png","sizes":"72x72"},{"src":"/image/shortcut/book-x96.png","sizes":"96x96"},{"src":"/image/shortcut/book-x128.png","sizes":"128x128"},{"src":"/image/shortcut/book-x192.png","sizes":"192x192"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a8c8d6b9a308134e790c53f6cabc6efd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/about","/story","/contact","/menu","/menu/lunch","/menu/dinner","/menu/wine-spirits","/menu/happy-hour","/menu/dessert"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
