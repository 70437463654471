/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Prevent page refresh when service worker (the site) is updated
// https://www.ziebikiewicz.com/adding-update-button-to-gatsby-app#how-to-prevent-auto-reload-on-page-navigation
export const onServiceWorkerUpdateReady = () => {
  // Set window.___swUpdated to prevent update on page navigation.
  // Overrides https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby/cache-dir/navigation.js#L64
  window.___swUpdated = false
}
